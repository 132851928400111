<script>
import CalendarEventsService from "@/services/CalendarEventsService";
import BCardActions from "@/@core/components/b-card-actions/BCardActions.vue";
import OwnerCalendarEventModal from "@/views/owners/viewOwner/OwnerCalendarEventModal.vue";

export default {
  name: 'OwnerCalendarEvents',
  components: {OwnerCalendarEventModal, BCardActions},
  props: {
    owner: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      calendarEvents: [],
      headers: [
        {
          key: 'model',
          label: 'Level',
        },
        {
          key: 'title',
          label: 'Title',
        },
        {
          key: 'content',
          label: 'Content',
        },
        {
          key: 'start_date',
          label: 'Start Date Time',
          sortable: true,
        },
        {
          key: 'end_date',
          label: 'End Date Time',
          sortable: true,
        },
      ],
      loading: false,
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 25,
        start_date: null,
        end_date: null,
        level: "",
      },
      total_count: 0,
      modalId: "owner-calendar-event-modal",
      selectedEvent: null,
    };
  },
  mounted() {
    this.getOwnerCalendarEvents();
  },
  computed: {
    modalTitle() {
      return this.selectedEvent ? 'Update Calendar Event' : 'Create Calendar Event';
    }
  },
  methods: {
    refresh() {
      this.closeModals();
      this.currentPage = 1;
      this.getMoreOwnerCalendarEvents(this.currentPage);
    },
    getMoreOwnerCalendarEvents(page) {
      this.filters.page = page - 1;
      this.getOwnerCalendarEvents();
    },
    async getOwnerCalendarEvents() {
      this.$refs.refreshCard.showLoading = true;
      try {
        const response = await CalendarEventsService.getOwnerEvents(this.owner.uid, this.filters);
        this.calendarEvents = response.data.data;
        this.filters.page = response.data.meta.page;
        this.filters.page_size = response.data.meta.page_size;
        this.total_count = response.data.meta.total_count;
      } catch (e) {
        const response = e.response;
        let errorText = "Could not get owner events, please refresh and try again"
        if (response && response.data.error) {
          errorText = response.data.error;
        }
        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        });
      } finally {
        setTimeout(() => {
          this.$refs.refreshCard.showLoading = false
        }, 100)
      }
    },
    openCreateModal() {
      if (!this.$can('Create', 'CalendarEvents')) {
        return;
      }
      this.selectedEvent = null;
      this.$bvModal.show(this.modalId);
    },
    openEditModal(item) {
      if (item.model !== 'Owner' || !this.$can('Update', 'CalendarEvents')) {
        return;
      }
      this.selectedEvent = item;
      this.$bvModal.show(this.modalId);
    },
    closeModals() {
      this.$bvModal.hide(this.modalId);
    },
  },
}
</script>

<template>
  <div v-if="$can('Read', 'CalendarEvents')">
    <b-card-actions action-refresh @refresh="refresh()" title="Calendar Events" class="mb-0" ref="refreshCard" no-body>
      <b-card-body class="pb-0">
        <b-card-sub-title>
          Listed below are all of the calendar events visible to the owner. You can select which level you would like to
          view the events at (Enterprise, Group, or Owner) and also filter using a from and until date.
        </b-card-sub-title>
        <div class="m-1 mb-0">
          <b-row class="mb-1">
            <b-col cols="3" class="px-0">
              <b-btn id="createButton" variant="primary" class="w-100" @click="openCreateModal">
                Create Event
              </b-btn>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="3" class="px-0 mr-1">
              <label for="eventLevel">Event level</label>
              <b-form-select v-model="filters.level" id="eventLevel" text="Event level" @change="refresh()">
                <b-form-select-option value="">All</b-form-select-option>
                <b-form-select-option value="Enterprise">Enterprise</b-form-select-option>
                <b-form-select-option value="Group">Group</b-form-select-option>
                <b-form-select-option value="Owner">Owner</b-form-select-option>
              </b-form-select>
            </b-col>
            <b-col cols="3" class="px-0 mr-1">
              <label for="startDate">From</label>
              <b-datepicker v-model="filters.start_date" id="startDate" @input="refresh()" :reset-button="true"/>
            </b-col>
            <b-col cols="3" class="px-0">
              <label for="endDate">Until</label>
              <b-datepicker v-model="filters.end_date" id="endDate" @input="refresh()" :reset-button="true"/>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
      <section>
        <b-table
          class="data-table mb-0"
          hover
          :items="calendarEvents"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
          show-empty
          :responsive="true"
          @row-clicked="openEditModal"
        >
          <template #cell(level)="data">
            {{ data.item.model }}
          </template>
          <template #cell(title)="data">
            <div class="line-clamp">
              {{ data.item.title }}
            </div>
          </template>
          <template #cell(content)="data">
            <div class="line-clamp">
              {{ data.item.content }}
            </div>
          </template>
          <template #cell(start_date)="data">
            {{ data.item.start_date | formatDateTime }}
          </template>
          <template #cell(end_date)="data">
            {{ data.item.end_date | formatDateTime }}
          </template>
        </b-table>
        <b-row class="mx-1">
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreOwnerCalendarEvents" :total-rows="total_count" :per-page="filters.page_size"
                          v-model="currentPage" class="my-0"/>
          </b-col>
        </b-row>
      </section>
    </b-card-actions>

    <b-modal :id="modalId" :title="modalTitle" hide-footer no-close-on-backdrop>
      <OwnerCalendarEventModal
        :event="selectedEvent"
        :owner="owner"
        @close="closeModals"
        @refresh="refresh"
      />
    </b-modal>
  </div>
</template>

<style>
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
