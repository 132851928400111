<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title>7 Plus - Online Streaming</b-card-title>
      </div>
      <div class="d-flex align-items-center">
        <b-form-select id="duration" v-model="filters.period" @change="getMoreActivities(1)">
          <b-form-select-option value="7days">Last 7 Days</b-form-select-option>
          <b-form-select-option value="3months">3 Months</b-form-select-option>
          <b-form-select-option value="6months">6 Months</b-form-select-option>
        </b-form-select>
      </div>
    </b-card-header>
    <b-table hover small :items="items" :fields="fields">
      <template #cell(description)="data">
        <a v-b-tooltip.hover.top="data.item.description" :href="data.item.target">
          {{ data.item.description | str_limit(55) }}
        </a>
      </template>
      <template #cell(count)="data">
        <b-badge variant="light-primary">
          {{ data.item.count }}
        </b-badge>
      </template>
    </b-table>

    <div v-if="noData" class="text-center m-3">
      No Activity found
    </div>

    <b-pagination v-if="total_count > filters.page_size"
                  v-model="currentPage"
                  :total-rows="total_count"
                  :per-page="filters.page_size"
                  class="my-1 float-right"
                  @change="getMoreActivities"
    />
  </b-card>
</template>

<script>

import OwnersService from '@/services/OwnersService';
import helperService from '@/services/HelperService';
import { BTable, VBTooltip } from 'bootstrap-vue';

export default {
  components: {
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    uid: {
      required: false,
      type: String,
      default: '',
    },
  },
  data() {
    return {
      noData: false,
      filters: {
        page: 0,
        page_size: 10,
        search: '',
        app: 'com.madepurple.channel7',
        period: '6months',
        owner_uid: '',
      },
      total_count: 0,
      currentPage: 1,
      items: [],
      fields: ['description', {
        key: 'count',
        label: '',
        thClass: 'text-right',
        tdClass: 'text-right',
      }],
    }
  },
  mounted() {
    if (this.uid !== '') {
      this.filters.owner_uid = this.uid
    }
    this.getTopActivities();
  },
  methods: {
    getMoreActivities(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.currentPage = val
      this.getTopActivities();
    },
    async getTopActivities() {
      this.loading = true
      OwnersService.getTopActivities(this.filters).then(res => {
        this.items = res.data.data;
        this.noData = res.data.data.length === 0
        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;
      }).catch(err => {
        helperService.showNotfyErr(this.$toast, err, 'Could not get activities, please refresh and try again')
      })
      .finally(() => {
        this.loading = false
      });
    },
  },
}
</script>
<style>
table.td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
