<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div v-if="$can('Read', 'Possessions')">
    <b-card-actions no-actions title="Volumetric Controls" class="mb-0" no-body>
      <section class="m-2">
        <b-row class="mb-2">
          <b-col cols="4" v-if="$can('Read', 'Locations')">
            <label>Location</label>
            <model-list-select :list="locations"
                               :class="{'disabled': locations.length === 0}"
                               v-model="selectedLocation"
                               option-value="id"
                               option-text="name"
                               clearable
                               placeholder="Select location"/>
          </b-col>
        </b-row>
      </section>
      <section>
        <VolumetricControl :location-id="selectedLocation" :owner-uid="this.uid"/>
      </section>
    </b-card-actions>

    <b-card-actions no-actions title="Owner Possessions" class="mb-0" no-body>
      <section>
        <PossessionsTable
          :show-create-button="false"
          :show-owner-filter="false"
          :default-filters="{owner_id: this.uid}"/>
      </section>
    </b-card-actions>
  </div>
</template>
<!--eslint-enable-->

<script>
  import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
  import PossessionsTable from "@/views/possessions/sections/PossessionTable.vue";
  import VolumetricControl from "@/views/possessions/sections/VolumetricControl.vue";
  import PossessionLocationsService from "@/services/LocationsService";
  import {ModelListSelect} from "vue-search-select";

  export default {
    name: 'OwnerDevices',
    components: {
      ModelListSelect,
      VolumetricControl,
      PossessionsTable,
      BCardActions,
    },
    props: {
      uid: {
        required: true,
        type: String,
      },
    },
    data() {
      return {
        locations: [],
        selectedLocation: 1,
      };
    },
    mounted() {
      this.getLocations();
    },
    methods: {
      async getLocations() {
        try {
          const res = await PossessionLocationsService.getLocations({
            page: 0,
            page_size: 50,
            search: this.locationSearchVal,
          })
          this.locations = res.data.data;
        } catch(err) {
          const res = err.response
          let errorText = 'Could not get locations, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        }
      },
    },
  };
</script>
