<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div v-if="$can('Read', 'Activity')">
    <b-card-actions action-refresh @refresh="$emit('refresh')" title="Devices" class="mb-0" no-body>
      <section>
        <b-table
          class="data-table"
          hover
          :items="devices"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
          show-empty
          :responsive="true"
        >
          <template #cell(updated_at)="data">
            {{data.item.updated_at | formatDateTime}}
          </template>
          <template #cell(is_device_secure)="data">
            {{data.item.is_device_secure | boolToYes}}
          </template>

          <template #cell(group_policy_version)="data">
            <PolicyInfo :group-version="data.item.group_policy_version" :applied-version="data.item.applied_group_policy_version"/>
          </template>


          <template #cell(policy_compliant)="data">
            {{data.item.policy_compliant | boolToYes}}
          </template>
          <template #cell(hardware_info_serial_number)="data">
            <router-link :to="{ name: 'device-view', params: { uid: data.item.uid } }" class="font-weight-bold d-block text-nowrap">
              {{data.item.hardware_info_serial_number}}
            </router-link>
          </template>
        </b-table>
      </section>
    </b-card-actions>
  </div>
</template>
<!--eslint-enable-->

<script>
  import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
  import PolicyInfo from "@/views/devices/viewDevice/sections/PolicyInfo.vue";

  export default {
    name: 'OwnerDevices',
    components: {
      PolicyInfo,
      BCardActions,
    },
    props: {
      devices: {
        required: true,
        type: Array,
      },
    },
    data() {
      return {
        headers: [
          // {
          //   key: 'prison_number',
          //   label: `${this.$options.filters.term('prison')} Number`,
          // },
          {
            key: 'hardware_info_serial_number',
            label: 'Serial Number',
          },
          {
            key: 'hardware_info_brand',
            label: 'Brand',
          },
          {
            key: 'is_device_secure',
            label: 'Device Secure',
          },
          {
            key: 'policy_compliant',
            label: 'Policy Compliant',
          },
          {
            key: 'group_policy_version',
            label: 'Group Version',
          },
          {
            key: 'api_level',
            label: 'API Level',
          },
          {
            key: 'updated_at',
            label: 'Updated At',
          },
          {
            key: 'actions',
            label: '',
            class: 'text-right',
          },
        ],
        currentPage: 1,
        filters: {
          page: 0,
          page_size: 25,
        },
        total_count: 0,
      };
    },
    mounted() {},
    methods: {},
  };
</script>
