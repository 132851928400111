<!--eslint-disable-->
<template>
  <div>
    <date-range-selector @update="updateDateRange" />

    <div v-if="$can('Read', 'Owner')">
      <b-row class="match-height">
        <b-col md="6" sm="12">
          <top-activities :from="fromDateISOString" :to="toDateISOString" :uid="this.uid" />
        </b-col>
        <b-col md="6" sm="16">
          <top-tags :uid="this.uid" :from="fromDateISOString" :to="fromDateISOString" />
        </b-col>
        <b-col md="6" sm="12" v-if="sevenPlusAllowed">
          <seven-plus :uid="this.uid" />
        </b-col>
        <b-col md="6" sm="12" v-if="nineNowAllowed">
          <nine-now :uid="this.uid" />
        </b-col>
      </b-row>

      <b-row v-if="$can('Read', 'Owner')" class="match-height">
        <b-col>
          <logins :from="fromDateISOString" :to="toDateISOString" :uid="this.uid" />
        </b-col>
      </b-row>

      <b-row v-if="$can('Read', 'Owner')" class="match-height">
        <b-col>
            <activity-count :uid="this.uid" />
          </b-col>
      </b-row>
    </div>
  </div>
</template>
<!--eslint-enable-->

<script>
  import TopActivities from '@/components/charts/TopActivities.vue';
  import ActivityCount from '@/components/charts/ActivityCount.vue';
  import SevenPlus from '@/components/tables/SevenPlus.vue';
  import NineNow from '@/components/tables/NineNow.vue';
  import ApplicationsService from "@/services/ApplicationsService";
  import DateRangeSelector from "@/components/charts/DateRangeSelector.vue";
  import TopTags from "@/components/charts/TopTags.vue";
  import Logins from "@/components/charts/Logins.vue";

  export default {
    name: 'ActivityOverview',
    components: {
      TopTags,
      DateRangeSelector,
      NineNow,
      SevenPlus,
      TopActivities,
      ActivityCount,
      Logins,
    },
    props: {
      uid: {
        required: true,
        type: String,
      },
    },
    data() {
      return {
        sevenPlusAllowed: false,
        nineNowAllowed: false,
        from: new Date(new Date().setDate(new Date().getDate() -  7)),
        to: new Date(),
      };
    },
    computed: {
      fromDateISOString() {
        let date = new Date(this.from);
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
      },
      toDateISOString() {
        let date = new Date(this.to);
        return new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
      },
    },
    mounted() {
      // Get the applications
      this.getApplications()
    },
    methods: {
      async getApplications() {
        const appRequest = await ApplicationsService.getApplications();
        if (appRequest.data) {
          appRequest.data.forEach(item => {
            if (item.package_name === 'com.madepurple.channel7') {
              this.sevenPlusAllowed = true
            }
            if (item.package_name === 'com.madepurple.channel9') {
              this.nineNowAllowed = true
            }
          })
        }
      },
      updateDateRange(from, to) {
        this.from = from
        this.to = to
      }
    },
  };
</script>
