<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div class="owners-page">

    <section v-if="!loading">
      <div class="row">
        <div class="col-12">
          <b-card class="">
            <section v-if="owner" class="row">
              <div class="col-md-3">
                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="TabletIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <b-card-text class="font-small-3 mb-0">
                      Identifier
                    </b-card-text>
                    <h4 class="font-weight-bolder mb-0">
                      {{owner.identifier}}
                    </h4>
                  </b-media-body>
                </b-media>
              </div>

              <div class="col-md-3" v-if="owner.additional_info">

                <b-media no-body>
                  <b-media-aside class="mr-2">
                    <b-avatar size="48" variant="light-primary">
                      <feather-icon size="24" icon="UserIcon" />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <b-card-text class="font-small-3 mb-0">
                      Additional Information
                    </b-card-text>
                    <h4 class="font-weight-bolder mb-0">
                      {{owner.additional_info}}
                    </h4>
                  </b-media-body>
                </b-media>

              </div>

              <div class="col-md-4">
                <router-link :to="{ name: 'group-view', params: { uid: owner.group_uid } }" class="font-weight-bold d-block text-nowrap">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-primary">
                        <feather-icon size="24" icon="CheckCircleIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <b-card-text class="font-small-3 mb-0">
                        Group
                      </b-card-text>
                      <h4 class="font-weight-bolder mb-0">
                        {{owner.group_name}} (v{{ owner.group_policy_version }})
                      </h4>
                    </b-media-body>
                  </b-media>
                </router-link>
              </div>

              <div class="col-md-2">
                <b-dropdown id="dropdown-grouped" block variant="primary" right text="Manage" class="manage-btn" >
                  <b-dropdown-group id="dropdown-group-1" header="User Actions">
                    <b-dropdown-item v-if="$can('Update', 'Owner')" @click="showOwnerModal('modal-update-owner')">
                      Update Owner Details
                    </b-dropdown-item>
                    <b-dropdown-item v-if="$can('Update', 'Owner')" @click="showOwnerModal('modal-password-owner')">
                      Change Device Pin
                    </b-dropdown-item>
                    <b-dropdown-item variant="danger" v-if="$can('Delete', 'Owner')" @click="showOwnerModal('modal-delete-owner')">
                      Delete Owner
                    </b-dropdown-item>
                  </b-dropdown-group>

                  <b-dropdown-divider />
                  <b-dropdown-group id="dropdown-group-2" header="Devices">
                    <b-dropdown-item v-if="$can('Update', 'Owner')" @click="showOwnerModal('modal-policy-owner')">
                      Change Group
                    </b-dropdown-item>

                    <b-dropdown-item v-if="$can('Delete', 'Device')" @click="showOwnerModal('modal-otp')">
                      OTP
                    </b-dropdown-item>

                  </b-dropdown-group>


                  <div v-if="$can('Manage', 'All')">
                    <b-dropdown-divider />
                    <b-dropdown-group id="dropdown-group-2" header="Purple Account">
                      <b-dropdown-item v-if="owner.purple_account_id > 0">
                        Purple Account ID: {{owner.purple_account_id}}
                      </b-dropdown-item>
                      <b-dropdown-item v-if="owner.purple_account_id === 0" @click="linkPurpleAccount">
                        Link Account
                      </b-dropdown-item>
                    </b-dropdown-group>
                  </div>
                </b-dropdown>
              </div>

            </section>
            <section v-else>Could not get owner</section>
          </b-card>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <b-tabs>
            <b-tab active>
              <template #title>
                <feather-icon icon="ActivityIcon" />
                <span>Activity Overview</span>
              </template>
              <activity-overview :uid="owner.uid"></activity-overview>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="AirplayIcon" />
                <span>Device Activities</span>
              </template>
              <owner-activities :uid="owner.uid"></owner-activities>
            </b-tab>
            <b-tab>
              <template #title>
                <feather-icon icon="TabletIcon" />
                <span>Devices</span>
              </template>
              <owner-devices @refresh="refreshOwner()" :devices="owner.devices"></owner-devices>
            </b-tab>
            <b-tab v-if="$can('Read', 'Activity')">
              <template #title>
                <feather-icon icon="SaveIcon" />
                <span>Update History</span>
              </template>
              <model-activities model="owner" :uid="owner.uid"></model-activities>
            </b-tab>
            <b-tab v-if="$can('Read', 'Wallet') && user.enterprise_wallet_enabled">
              <template #title>
                <feather-icon icon="CreditCardIcon" />
                <span>Wallet</span>
              </template>
              <model-wallet-transactions model="owner" :uid="owner.uid"></model-wallet-transactions>
            </b-tab>
            <b-tab v-if="$can('Read', 'CalendarEvents')">
              <template #title>
                <feather-icon icon="CalendarIcon" />
                <span>Calendar</span>
              </template>
              <owner-calendar-events :owner="owner"/>
            </b-tab>
            <b-tab v-if="$can('Read', 'Wallet') && user.enterprise_wallet_enabled">
              <template #title>
                <feather-icon icon="ArchiveIcon" />
                <span>Possessions</span>
              </template>
              <owner-possessions model="owner" :uid="owner.uid"></owner-possessions>
            </b-tab>
          </b-tabs>
        </div>
      </div>

      <b-modal id="modal-update-owner" title="Update owner" hide-footer>
        <update-owner :owner-existing="owner" @refresh="refreshOwner()" @close="closeModals()"></update-owner>
      </b-modal>

      <b-modal id="modal-delete-owner" title="Delete owner" hide-footer>
        <delete-modal
          @close="closeModals()" @delete="deleteOwner(owner)"
          :subtitle="owner.identifier"
          title="Are you sure you wish to delete owner"></delete-modal>
      </b-modal>

      <b-modal id="modal-password-owner" title="Change owner pin" hide-footer>
        <change-password-owner v-if="owner" :owner="owner" @refresh="refreshOwner()" @close="closeModals()"></change-password-owner>
      </b-modal>

      <b-modal id="modal-policy-owner" title="Update Group Policy" hide-footer>
        <switch-owner-policy-group @close="closeModals()" @refresh="refreshOwner()" :owner="owner"></switch-owner-policy-group>
      </b-modal>

      <b-modal id="modal-policy-owner" title="Update Group Policy" hide-footer>
        <switch-owner-policy-group @close="closeModals()" @refresh="refreshOwner()" :owner="owner"></switch-owner-policy-group>
      </b-modal>

      <b-modal id="modal-otp" title="Owner OTP" hide-footer>
        <otp @close="closeModals()" title="Generate an OTP" :ownerUid="uid"></otp>
      </b-modal>

    </section>

    <section v-else>
      <b-spinner variant="primary" label="Loading owner"></b-spinner>
    </section>


  </div>
</template>
<!--eslint-enable-->

<script>
  import OwnerDevices from '@/views/owners/viewOwner/OwnerDevices.vue';
  import OwnerActivities from '@/views/owners/viewOwner/OwnerActivities.vue';
  import ChangePasswordOwner from '@/views/owners/sections/ChangePasswordOwner.vue';
  import SwitchOwnerPolicyGroup from '@/views/owners/sections/SwitchOwnerPolicyGroup.vue';
  import { BAvatar } from 'bootstrap-vue';
  import ActivityOverview from '@/views/owners/viewOwner/ActivityOverview.vue';
  import { mapState } from 'vuex';
  import OwnersService from '../../services/OwnersService'
  import UpdateOwner from './sections/UpdateOwner.vue'
  import DeleteModal from '../../components/modals/DeleteModal.vue';
  import ModelActivities from '../activities/ModelActivities.vue';
  import ModelWalletTransactions from '../wallet/ModelWalletTransactions.vue';
  import Otp from './sections/Otp.vue'
  import OwnerCalendarEvents from "@/views/owners/viewOwner/OwnerCalendarEvents.vue";
  import OwnerPossessions from "@/views/owners/viewOwner/OwnerPossessions.vue";

  export default {
    name: 'Owner',
    components: {
      OwnerCalendarEvents,
      OwnerPossessions,
      ActivityOverview,
      BAvatar,
      DeleteModal,
      UpdateOwner,
      ModelActivities,
      ModelWalletTransactions,
      OwnerDevices,
      OwnerActivities,
      ChangePasswordOwner,
      SwitchOwnerPolicyGroup,
      Otp,
    },
    props: {
      uid: {
        required: true,
        type: String,
      },
    },
    data() {
      return {
        loading: true,
        owner: null,
      };
    },
    computed: {
      ...mapState({
        user: state => state.user.currentUser,
      }),
    },
mounted() {
      this.getOwner();
    },
    methods: {
      refreshOwner() {
        this.closeModals();
        this.getOwner();
      },
      closeModals() {
        this.$bvModal.hide('modal-delete-owner');
        this.$bvModal.hide('modal-update-owner');
        this.$bvModal.hide('modal-qr');
        this.$bvModal.hide('modal-policy-owner');
        this.$bvModal.hide('modal-password-owner');
      },
      getOwner() {
        this.loading = true;
        OwnersService.getOwner(this.uid).then(res => {
          this.owner = res.data;
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not get owner, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        }).finally(() => {
          this.loading = false;
        });
      },
      showOwnerModal(modalName) {
        this.$bvModal.show(modalName);
      },
      deleteOwner(owner) {
        OwnersService.deleteOwner(owner.uid).then(() => {
          this.$toast.success(`Deleted owner ${owner.identifier} Successfully`, {
            toastClassName: ['toast-std', 'success-toast'],
          });

          this.$router.push({ name: 'owners' });
        }).catch(err => {
          const res = err.response
          let errorText = 'Could not delete owner, please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        });
      },
      linkPurpleAccount() {
        OwnersService.linkPurpleAccount(this.owner.uid).then(() => {
          this.$toast.success('Owner Account Linked Successfully', {
            toastClassName: ['toast-std', 'success-toast'],
          });
          this.refreshOwner();
        }).catch(err => {
          const errorMessage = err && err.response && err.response.data && err.response.data.error;
          const msg = 'Could not link owner account, please refresh and try again';
          this.$toast.error(errorMessage ? err.response.data.error : msg, {
            toastClassName: ['toast-std', 'warning-toast'],
          });
        });
      },
    },
  };
</script>
<style>
.manage-btn {
  margin-top: 5px;
}
</style>
