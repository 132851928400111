<style lang="scss"></style>

<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(removeFunds)">
        <section class="mb-2">
          <div>
            <label>Amount to remove</label>
            <validation-provider
              v-slot="validationContext"
              name="Amount"
              :rules="{ required: true, min_value:0.01, max_value:1000}"
              :custom-messages="{required:'Please enter a valid amount', min_value: 'Amount must be greater than £0', max_value: 'Can not remove more than £1000'}"
            >
              <b-form-input
                v-model="userAmount"
                :state="getValidationState(validationContext)"
                type="number" step="0.01" min="0.00" max="1000.00" class="mb-1"
              />

              <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
            </validation-provider>
            <h4 v-if="userAmount*100 !== 0">Amount to remove: {{ userAmount*100 | intToCurrency }}</h4>
            <h4 v-else>Amount to remove: £0</h4>
          </div>
        </section>

        <section class="d-inline-flex full-width mt-2">
          <div class="ui-spacer" />
          <b-button class="mr-2" @click="$emit('close', true)">Cancel</b-button>
          <b-button variant="primary" type="submit">Remove Funds</b-button>
        </section>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import WalletService from '@/services/WalletService';

export default {
  components: {},
  props: {
    ownerUid: {
      required: true,
      type: String,
    },
    ownerBalance: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      userAmount: null,
      removeFundsRequest: {
        owner_uid: this.ownerUid,
        amount: null,
        idempotency_key: '',
      },
    };
  },
  mounted() {
    this.generateIdempotencyKey()
  },
  methods: {
    async removeFunds() {
      try {
        this.removeFundsRequest.amount = Number(this.userAmount).toFixed(2) * 100
        await WalletService.RemoveFunds(this.removeFundsRequest)
        this.$toast.success('Funds successfully removed', {
          toastClassName: ['toast-std', 'success-toast'],
        });
        this.$emit('close', true)
      } catch (err) {
        const res = err.response
        let errorText = 'Could not remove funds from owners balance, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    generateIdempotencyKey() {
      const keyLength = 20
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = ' ';
      const charactersLength = characters.length;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < keyLength; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      this.removeFundsRequest.idempotency_key = result
    },
  },
};
</script>
