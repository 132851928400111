<!--eslint-disable-->
<template>
  <section class="text-center" v-if="loaded">
    <h3>{{title}}</h3>
    <h4 v-if="subtitle" class="font-weight-bolder my-2">{{subtitle}}</h4>

    <section class="mt-1" v-show="totpRequired">
      <p>Here is a OTP for the owner</p>
      <h3>{{ totpCode }}</h3>
      <h5>Time left before refresh: {{ timer }}</h5>
    </section>

    <section class="mt-2 text-left" v-if="$ability.can('Manage', 'All')">
      <p>If your device is out-of-sync, enter its exact datetime here, then press sync.</p>
      <b-datepicker v-model="syncDate"></b-datepicker>
      <b-timepicker class="mt-1" v-model="syncTime" show-seconds></b-timepicker>
      <div class="d-flex flex-row align-items-center justify-content-between mt-1">
        <b-button variant="primary" @click="syncTotp">Sync</b-button>
        <b-button @click="resetTotp">Clear</b-button>
      </div>
    </section>
  </section>
</template>
<!--eslint-enable-->

<script>
  import { mapState } from 'vuex';
  import OwnersService from '@/services/OwnersService';

  export default {
    components: {},
    props: {
      title: {
        required: true,
        type: String,
      },
      // eslint-disable-next-line vue/require-default-prop
      ownerUid: {
        required: false,
        type: String,
      },
      // eslint-disable-next-line vue/require-default-prop
      subtitle: {
        required: false,
        type: String,
      },
      // eslint-disable-next-line vue/require-default-prop
      note: {
        required: false,
        type: String,
      },
      okVariant: {
        required: false,
        type: String,
        default: 'danger',
      },
    },
    data() {
      return {
        accepted: 'not_accepted',
        totpRequired: false,
        totpCode: null,
        timer: 30,
        loaded: false, // used to change color variant as bootstrap does not update after mount
        syncDate: null,
        syncTime: null,
        syncTimestamp: null,
        syncIntervalID: null,
      };
    },
    computed: {
     ...mapState({
        user: state => state.user.currentUser,
      }),
    },
    mounted() {
      if (this.user.mdm_version > 1) {
        this.totpRequired = true

        // Generate TOTP code for page every 30 seconds
        this.getTotp()
        setInterval(() => {
          this.getTotp()
        }, 30000)

        // Reduce the Timer by 1 every second
        setInterval(() => {
          this.timer -= 1
        }, 1000)
      }
      setTimeout(() => {
        this.loaded = true;
      });
    },
    beforeDestroy() {
      // Clear intervals
      clearInterval()

      if (this.syncIntervalID) clearInterval(this.syncIntervalID)
    },
    methods: {
      getTotp() {
        OwnersService.getTotp(this.ownerUid, this.syncTimestamp ? this.syncTimestamp : null).then(response => {
          // Set the totp and the refresh timer
          this.totpCode = response.data.totp
          this.timer = 30
        }).catch(err => {
          const res = err.response

          // Default error messages
          let errorText = 'Could not generate code, please refresh and try again'
          this.totpCode = 'please refresh and try again'

          if (res && res.data.error) {
            errorText = res.data.error
          }

          this.$toast.error(errorText, {
            toastClassName: ['toast-std', 'warning-toast'],
          })
        })
      },
      syncTotp() {
        if (!this.syncDate || !this.syncTime) return

        if (this.syncIntervalID) clearInterval(this.syncIntervalID)

        this.syncTimestamp = new Date(`${this.syncDate}T${this.syncTime}`)

        this.syncIntervalID = setInterval(() => {
          this.syncTimestamp.setTime(this.syncTimestamp.getTime() + 1000)
          this.syncDate = this.syncTimestamp
          this.syncTime = this.syncTimestamp.toTimeString().slice(0, 8)
        }, 1000)

        this.getTotp()
      },
      resetTotp() {
        this.syncDate = null
        this.syncTime = null
        this.syncTimestamp = null

        if (this.syncIntervalID) {
          clearInterval(this.syncIntervalID)
          this.syncIntervalID = null
        }

        this.getTotp()
      },
    },
  };
</script>
