<style lang="scss">

</style>
<!--eslint-disable-->
<template>
  <div v-if="$can('Read', 'Wallet')" :class="{'disabled': loading}">
    <b-card-actions action-refresh action-collapse @refresh="getMoreWalletTransactions(1)" title="Wallet Transactions" ref="refreshCard" class="" no-body>
      <b-card-body>
        <b-card-sub-title>This table shows the most recent transactions for the owners wallet.</b-card-sub-title>
      </b-card-body>

      <div class="m-1">
        <b-row>
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-between">
              <b-card-title v-if="balance !== 0" class="m-0">Current Balance: {{balance | intToCurrency}}</b-card-title>
              <b-card-title v-else class="m-0">Current Balance: £0</b-card-title>

              <div>
                <b-button v-if="$can('Create', 'Wallet')" class="ml-1" @click="showWalletModal('modal-add-funds')" variant="primary">Add funds</b-button>
                <b-button v-if="$can('Create', 'Wallet')" class="ml-1" @click="showWalletModal('modal-remove-funds')" variant="danger">Remove funds</b-button>
              </div>

            </div>
          </b-col>
        </b-row>
      </div>


      <section>
        <b-table
          class="data-table"
          hover
          :items="walletTransactions"
          :fields="headers"
          :current-page="currentPage"
          :per-page="0"
        >
          <template #cell(created_at)="data">
            {{data.item.created_at | formatDateTime}}
          </template>

          <template #cell(amount)="data">
            {{data.item.amount | intToCurrency}}
          </template>

          <template #cell(user)="data">
            <div v-if="data.item.user">
              <router-link :to="{ name: 'user-view', params: { uid: data.item.user.uid } }" class="font-weight-bold d-block text-nowrap">
                {{ data.item.user.name}}
              </router-link>
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col md="6" class="my-1" v-if="total_count > filters.page_size">
            <b-pagination @change="getMoreWalletTransactions" :total-rows="total_count" :per-page="filters.page_size" v-model="currentPage" class="my-0"/>
          </b-col>
          <p v-if="total_count === 0" class="col-6 my-1 mx-2">No records</p>
        </b-row>
      </section>
    </b-card-actions>

    <b-modal id="modal-add-funds" title="Add funds to wallet" hide-footer>
      <add-funds :ownerUid="uid" @close="closeModals"/>
    </b-modal>

    <b-modal id="modal-remove-funds" title="Remove funds from wallet" hide-footer>
      <remove-funds :ownerBalance="balance" :ownerUid="uid" @close="closeModals"/>
    </b-modal>
  </div>

</template>
<!--eslint-enable-->

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import WalletService from '@/services/WalletService';
import OwnerService from '@/services/OwnersService';
import AddFunds from '@/views/wallet/sections/AddFunds.vue';
import RemoveFunds from '@/views/wallet/sections/RemoveFunds.vue';

export default {
  name: 'ModelWalletTransactions',
  components: {
    AddFunds,
    RemoveFunds,
    BCardActions,
  },
  props: {
    title: {
      required: false,
      type: String,
      default: 'Wallet Transactions',
    },
    model: {
      required: true,
      type: String,
    },
    uid: {
      required: true,
      type: String,
    },
    noBody: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      headers: [
        {
          key: 'created_at',
          label: 'Date',
        },
        {
          key: 'amount',
          label: 'Amount',
        },
        {
          key: 'type',
          label: 'Type',
        },
        {
          key: 'description',
          label: 'Description',
        },
        {
          key: 'user',
          label: 'Created By',
        },
      ],
      walletTransactions: [],
      currentPage: 1,
      filters: {
        page: 0,
        page_size: 15,
        owner_uid: this.uid,
      },
      total_count: 0,
      balance: 0,
    };
  },
  mounted() {
    this.getWalletTransactions();
  },
  methods: {
    getMoreWalletTransactions(val) {
      this.filters.page = val - 1; // api index starts at 0, bootstrap starts at 1 lol
      this.getWalletTransactions();
    },
    async getWalletTransactions() {
      try {
        this.$refs.refreshCard.showLoading = true
        const res = await WalletService.getModelWalletTransactions(this.filters)
        this.walletTransactions = res.data.data;
        this.filters.page = res.data.meta.page;
        this.filters.page_size = res.data.meta.page_size;
        this.total_count = res.data.meta.total_count;

        // Update displayed owner balance
        await this.getOwnerBalance();
      } catch (err) {
        const res = err.response
        let errorText = 'Could not get wallet transactions, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      } finally {
        setTimeout(() => {
          this.$refs.refreshCard.showLoading = false
        }, 100)
      }
    },
    async getOwnerBalance() {
      try {
        const res = await OwnerService.getBalance(this.uid)
        this.balance = res.data.balance
      } catch (err) {
        const res = err.response
        let errorText = 'Could not get owner balance, please refresh and try again'

        if (res && res.data.error) {
          errorText = res.data.error
        }

        this.$toast.error(errorText, {
          toastClassName: ['toast-std', 'warning-toast'],
        })
      }
    },
    showWalletModal(modalName) {
      this.$bvModal.show(modalName);
    },
    closeModals() {
      this.$bvModal.hide('modal-add-funds');
      this.$bvModal.hide('modal-remove-funds');
      this.getWalletTransactions()
    },
  },
};
</script>
